<template>
    <div class="contenedor_metodology">
        <b-container fluid>
            <b-row>
                <b-col sm="12" class="mt-3">
                    <div class="titulo_metodology">
                        Solutions
                    </div>
                </b-col>
                <b-col sm="12" class="mt-3">
                    <div class="people">
                        <img src="@/assets/gente.jpg" style="width: 100%;height: 100%;" alt="">
                    </div>
                </b-col>

                <b-col sm="12">
                    <div class="cuadro_gente_m">
                        <img src="@/assets/gente.jpg" style="width: 100%;height: 100%;" alt="">
                    </div>
                </b-col>

                <b-col sm="12">
                    <div class="seccion_r">
                        <div class="numeral_m">
                            01
                        </div>
                        <div class="texto_m">
                            BIG DATA
                        </div>
                    </div>

                    <div class="seccion_r">
                        <div class="numeral_m">
                            02
                        </div>
                        <div class="texto_m">
                            SMALL DATA
                        </div>
                    </div>

                    <div class="seccion_r">
                        <div class="numeral_m">
                            03
                        </div>
                        <div class="texto_m">
                            BENCHMARKING
                        </div>
                    </div>

                    <div class="seccion_r">
                        <div class="numeral_m">
                            04
                        </div>
                        <div class="texto_m">
                            PLANNING
                        </div>
                    </div>

                    <div class="seccion_r">
                        <div class="numeral_m">
                            05
                        </div>
                        <div class="texto_m">
                            LOCAL PARTNERS
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <div class="globo_rojo">
            <div class="numeral">
                01
            </div>
            <div class="texto" style="margin-top: 35px;">
                BIG DATA
            </div>
        </div>

        <div class="globo_rojo2">
            <div class="numeral2">
                02
            </div>
            <div class="texto" style="margin-top: 35px;">
                SMALL DATA
            </div>
        </div>


        <div class="globo_rojo3">
            <div class="numeral3">
                03
            </div>
            <div class="texto" style="margin-top: 40px;">
                BENCHMARKING
            </div>
        </div>


        <div class="globo_rojo4">
            <div class="numeral4">
                04
            </div>
            <div class="texto" style="margin-top: 30px;">
                PLANNING
            </div>
        </div>


        <div class="globo_rojo5">
            <div class="numeral5">
                05
            </div>
            <div class="texto" style="margin-top: 50px;font-size: 23px;">
                LOCAL PARTNERS
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Metology",
    data() {
        return {
            
        }
    },
    methods: {
        
    },
}
</script>

<style>

    @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

    .contenedor_metodology{
        width: 100%;
        height: 100%;
    }
        .titulo_metodology{
            font-family: 'Arvo', serif;
            font-size: 55px;
            letter-spacing: 6px;
            color: #383f4c;
            text-align: center;
        }

        .people{
            width: 70%;
            height: 1000px;
            min-width: 1100px;
            margin: auto;   
        }

        .globo_rojo{
            font-family: 'Arvo', serif;
            position: relative;
            width: 270px;
            height: 160px;
            position: absolute;
            top: 120px;
            left: 100px;
            background-color: #ed3338;
            color: white;
            border-radius: 5px;
            padding: 30px;
            box-sizing: border-box;
            font-size: 28px;
            letter-spacing: 1px;
            line-height: 35px;
        }
            .numeral{
                font-family: 'Montserrat', sans-serif;
                width: 180px;
                height: 50px;
                color: #383f4c;
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 7px;
                background-color: #81e1df;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                position: absolute;
                top: -25px;
                left: 45px;
            }

            .texto{
                font-weight: bold;
                text-align: center;
            }


    .globo_rojo2{
            font-family: 'Arvo', serif;
            position: relative;
            width: 375px;
            height: 160px;
            line-height: 35px;
            position: absolute;
            top: 420px;
            left: 300px;
            background-color: #ed3338;
            color: white;
            border-radius: 5px;
            padding: 30px;
            box-sizing: border-box;
            font-size: 28px;
            letter-spacing: 1px;
        }
            .numeral2{
                font-family: 'Montserrat', sans-serif;
                width: 180px;
                height: 50px;
                color: #383f4c;
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 7px;
                background-color: #81e1df;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                position: absolute;
                top: -25px;
                left: 100px;
            }
    
    .globo_rojo3{
            font-family: 'Arvo', serif;
            position: relative;
            width: 350px;
            height: 160px;
            position: absolute;
            top: 120px;
            right: 100px;
            background-color: #ed3338;
            color: white;
            border-radius: 5px;
            padding: 30px;
            box-sizing: border-box;
            font-size: 28px;
            letter-spacing: 1px;
            line-height: 35px;
        }
            .numeral3{
                font-family: 'Montserrat', sans-serif;
                width: 180px;
                height: 50px;
                color: #383f4c;
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 7px;
                background-color: #81e1df;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                position: absolute;
                top: -25px;
                left: 85px;
            }

    .globo_rojo4{
            font-family: 'Arvo', serif;
            position: relative;
            width: 370px;
            height: 160px;
            
            position: absolute;
            bottom: 80px;
            left: 100px;
            background-color: #ed3338;
            color: white;
            border-radius: 5px;
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            font-size: 28px;
            letter-spacing: 1px;
            line-height: 35px;
        }
            .numeral4{
                font-family: 'Montserrat', sans-serif;
                width: 190px;
                height: 50px;
                color: #383f4c;
                font-size: 30px;
                font-weight: 800;
                letter-spacing: 7px;
                background-color: #81e1df;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                position: absolute;
                top: -25px;
                left: 100px;
            }

    .globo_rojo5{
            font-family: 'Arvo', serif;
            position: relative;
            width: 300px;
            height: 160px;
            position: absolute;
            bottom: 80px;
            right: 100px;
            background-color: #ed3338;
            color: white;
            border-radius: 5px;
            padding-top: 20px;
            box-sizing: border-box;
            font-size: 28px;
            letter-spacing: 1px;
            line-height: 35px;
        }
            .numeral5{
                font-family: 'Montserrat', sans-serif;
                width: 190px;
                height: 50px;
                color: #383f4c;
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 7px;
                background-color: #81e1df;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                position: absolute;
                top: -25px;
                left: 60px;
            }

    .seccion_r{
        display: none;
    }

    .cuadro_gente_m{
        display: none;
    }

    .numeral_m{
        width: 60px;
        height: auto;
        background-color: #81e1df;
        color: #383f4c;
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 4px;
    }

    .texto_m{
        width: calc(100% - 60px);
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        
        color: white;
        font-family: 'Arvo', serif;
        letter-spacing: 1px;
        font-size: 20px;
    }

    @media only screen and (min-width : 320px) and (max-width: 768px){
        .people{
            display: none;
        }

        .globo_rojo{
            display: none;
        }

        .globo_rojo2{
            display: none;
        }

        .globo_rojo3{
            display: none;
        }

        .globo_rojo4{
            display: none;
        }

        .globo_rojo5{
            display: none;
        }

        .seccion_r{
            width: 100%;
            height: auto;
            background-color: #ed3338;
            display: flex;
            margin-bottom: 10px;
            border-radius: 5px;
        }

        .titulo_metodology{
            font-size: 40px;
        }

        .cuadro_gente_m{
            display: block;
            width: 100%;
            height: 400px;
            margin-bottom: 15px;
        }
    }
</style>