<template>
    <div class="contenedor_header">
        <!-- <a href="#" data-ancla="ancla1" class="ancla">Ancla</a> -->
        <div class="navar">
            <ul class="ul_header">
                <li class="li_header">
                    HOME
                </li>
                <li class="li_header">
                    <a href="#" data-ancla="who" class="ancla">WHO WE ARE</a>
                </li>
                <!-- <li class="li_header">
                    <a href="#" data-ancla="solutions" class="ancla">SOLUTIONS</a>
                </li> -->
                <li class="li_header">
                    <a href="#" data-ancla="methodology" class="ancla">SOLUTIONS</a>
                </li>
                <li class="li_header">
                    <a href="#" data-ancla="clients" class="ancla">CLIENTS</a>
                </li>
                <li class="li_header">
                    <a href="#" data-ancla="contactus" class="ancla">CONTACT US</a>
                    
                </li>
            </ul>

        </div>
        <div class="roboto">
            <img src="@/assets/Human-Robot.png" class="robo" style="width: 95%;" alt="">
        </div>

        

        <div class="logo_mkt">
            <img src="@/assets/MKT-Logo.png" style="width: 100%;" alt="">
        </div>

        <!-- <div class="texto1">
            Revolutionize
        </div>
        <div class="texto1">
            your Process;
        </div> -->
        <div class="espacio___">

        </div>
        <div class="texto1">
            Diversify
        </div>
        <div class="texto1">
            your Process
        </div>

        <div class="texto1__">
            Diversify <br> your
        </div>
        <div class="texto1__">
            Process
        </div>
        <!-- <div class="texto1">
            WANT AND WHY?
        </div> -->

        <div class="texto2">
            <!-- Our Passion is your Product, and we Guarantee a Progressive, Original, and Innovative Result. -->
        </div>

        <div class="texto2_m">
            <!-- TODAY EVERYTHING IS SEEN, <br> FELT AND PERCEIVED. -->
            <!-- Our Passion is your Product,  <br> and we Guarantee a Progressive,  <br> Original, and Innovative Result. -->
        </div>

        <div class="certificacion">
            <a target="_blank" href="https://www.designrush.com/agency/business-consulting/trends/rfp-process">
                <img src="@/assets/certificado.png" style="width: 100%;" alt="">
            </a>
        </div>

        <div class="redes">
            <div class="r_ico">
                <a target="_blank" href="https://www.facebook.com/planningmkt">
                    <img src="@/assets/Facebook.png" style="width: 100%;" alt="">
                </a>
                
            </div>
            <div class="r_ico">
                <a target="_blank" href="https://twitter.com/planningmkt">
                    <img src="@/assets/Twitter.png" style="width: 100%;" alt="">
                </a>
            </div>
            <div class="r_ico">
                <a href="https://www.youtube.com/watch?v=SuNsE4Zp0Kw" target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/YouTube.png" style="width: 100%;" alt="">
                </a>
            </div>
            <div class="r_ico">
                <a href="https://www.linkedin.com/company/planning-mkt" target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/Linkedin.png" style="width: 100%;" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Cabecera'
}
</script>

<style>



.navar{
    width: 100%;
    height: 120px;
    
    padding-top: 40px;
    z-index: 999;

    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.5256477591036415) 0%, rgba(255,255,255,0) 80%, rgba(255,255,255,0) 100%);
}

.ul_header{
    text-align: right;
}

.li_header{
    font-family: 'Montserrat', sans-serif;
    display: inline;
    padding-right: 80px;
    color: white;
}

.roboto{
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -350px;
    width: auto;
    height: 700px;
    z-index: -1;
    overflow: hidden;

}

.espacio___{
    height: 185px;
}

.logo_mkt{
    width: 280px;
    height: auto;
    margin-left: 100px;
    margin-bottom: 35px;
    
}

.texto1{
    margin-left: 100px;
    color: white;
    font-size: 70px;
    font-weight: 700;

    
    line-height: 73px;
}

.texto1__{
    display: none;
    
}

.colorsh{
    color: #85e1df;
}


.texto2{
    font-family: 'Montserrat', sans-serif;
    margin-left: 100px;
    margin-top: 30px;
    color: white;
    font-weight: bold;
}

.certificacion{
    border-radius: 50%;
    width: 168px;
    height: 153px;
    position: absolute;
    right: 79px;
    bottom: 85px;
    transition: .5s ease;
    cursor: pointer;
}

    .certificacion:hover{
        background-color: rgba(255, 255, 255, 0.4);
    }
.redes{
    
    width: auto;
    height: 40px;
    position: absolute;
    right: 70px;
    bottom: 40px;
    display: flex;
    align-items: center;
}

    .r_ico{
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

.texto2_m{
    display: none;
}


@media only screen and (min-width : 320px) and (max-width: 768px){
    .logo_mkt{
        margin-left: 20px;
        width: 150px;
    }

    .texto1{
        display: none;
        margin-left: 20px;
        font-size: 45px;
        line-height: 45px;
    }

    .espacio___{
        height: 310px;
    }

    .redes{
        right: 6px;
    }

    .certificacion{
        right: 15px;
    }

    .texto1__{
        display: block;
        margin-left: 10px;
        color: white;
        font-size: 35px;
        font-weight: 700;

        
        line-height: 35px;
    }

    .contenedor_header{
        
        width: 100%;
        height: 700px;
        position: absolute;
        
        background-image: url("../../assets/Human-Robot.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position-x: center;
    }

    .roboto{
        display: none;
    }

    .ul_header{
        display: none;
    }

    .texto2{
        display: none;
    }

    .texto2_m{
        display: block;
        font-family: 'Montserrat', sans-serif;
        margin-left: 15px;
        margin-top: 30px;
        color: white;
        font-weight: bold;
    }

}
</style>