<template>
    <div class="contenedor_solutions">
        <b-container fluid>
            <b-row>
                <b-col sm="12" class="mt-4">
                    <div class="solutions_titulo">
                        Let’s Breach Boundaries!
                    </div>
                    <div class="_componente_solution_smart">
                        <div class="solutions_btn">
                            Smart
                        </div>
                    </div>
                </b-col>
                <b-col sm="12" md="6" class="mt-4">
                    <div class="_contendor_logo_solutions">
                        <div class="foto_solutions">
                            <img src="@/assets/cubo.png" style="width: 100%;" alt="">
                        </div>
                    </div>
                    <div class="solutions_titulo_logo">
                        <div>
                            <div class="solution_line">
                                Inventive Marketing
                            </div>
                            <!-- <div class="solution_line">
                                SEGMENTATION
                            </div> -->
                        </div>
                    </div>
                    <div class="solutions_lista">
                       <div>
                            <div class="txt_internacional">            
                                Your company already has major potential, our job is simply to help you get there. We launch you ahead of the game by assisting in various types of critical thinking tasks such as:
                            </div>
                           <ul>
                               <li style="font-size: 22px;">
                                   Technical and Competitive Benchmarking
                               </li>
                               <li style="font-size: 22px;">
                                   Quantitative and Qualitative Marketing Segmentation
                               </li>
                               <li style="font-size: 22px;">
                                   Tactical Customer Segmentation
                               </li>
                               <!-- <li>
                                   Potential Buyers Segmentation
                               </li> -->
                           </ul>
                       </div>
                    </div>
                    
                </b-col>
                <b-col sm="12" md="6" class="mt-4">
                    <div class="_contendor_logo_solutions">
                        <div class="foto_solutions2" >
                            <img src="@/assets/mundito_opt.png" class="img_aglobal"  alt="">
                        </div>
                    </div>
                    <div class="solutions_titulo_logo">
                        <div >
                            <div class="solution_line">
                                A Global Approach
                            </div>
                            <!-- <div class="solution_line">
                                SALES
                            </div> -->
                        </div>
                    </div>
                    <div class="solutions_lista">
                        <div class="txt_internacional">
                            Planning Mkt’s international alliance helps to expand your company’s potential. With a global network of 37 countries, we will use our tools, experience, and dependable leaders to launch your next project!
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "Solutions",
    data() {
        return {
            
        }
    },
    methods: {
        
    },
}
</script>

<style>


    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;800&display=swap');

    .contenedor_solutions{
        width: 100%;
        height: 100%;
    }
        .solutions_titulo{
            font-family: 'Arvo', serif;
            font-weight: 900;
            font-size: 60px;
            color: #383f4c;
            text-align: center;
        }

        ._componente_solution_smart{
            display: flex;
            justify-content: center;
        }

        .solutions_btn{
            font-family: 'Arvo', serif;
            font-size: 25px;
            color: white;
            background-color: #1781d2;
            width: 160px;
            text-align: center;
        }

        .foto_solutions{
            width: 250px;
            height: 250px;
        }

        .foto_solutions2{
            width: 350px;
            height: 250px;
            overflow: hidden;
            position: relative;
        }

            .img_aglobal{
                width: 120%;
                position: absolute;
                top: 18px;
                left: -25px;
            }

        ._contendor_logo_solutions{
            display: flex;
            justify-content: center;
        }

    .solutions_titulo_logo{
        padding-top: 15px;
        display: flex;
        justify-content: center;
    }
        .solution_line{
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            font-size: 37px;
            line-height: 35px;
            color: #1781d2;
            letter-spacing: 10px;
        }

    .solutions_lista{
        font-family: 'Arvo', serif;
        margin-top: 30px;
        color: #383f4c;
        font-size: 25px;
        padding-left: 50px;
    }

    

    .txt_internacional{
        text-align: left;
    }

    @media only screen and (min-width : 320px) and (max-width: 768px){
        .solution_line{
            text-align: center;
            font-size: 25px;
        }
        
       
       .solutions_lista{
           padding-left: 0px;
       }
    }

</style>