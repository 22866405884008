<template>
    <div class="contenedor_clients">
        <div class="cliente_titulo">
            Clients
        </div>
        <div class="clientes_subtitulos">
            Special Brands, Special Services
        </div>
        <div class="logos_cliente">
            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:120px">
                <img src="@/assets/logos/Cotton_USA.jpg" style="width: 100%;" alt="">
            </div>
            <!-- <div class="logo2">
                <img src="@/assets/logos/BancoDeLaNacion.jpg" style="width: 100%;height:100%;" alt="">
            </div> -->
            <div class="logo" style="display: flex;justify-content:center;align-items:center">
                <img src="@/assets/logos/Dian.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo2">
                <img src="@/assets/logos/Freds.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo3">
                <img src="@/assets/logos/Gonher.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:200px">
                <img src="@/assets/logos/Quescrem.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;">
                <img src="@/assets/logos/Incae.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo3" style="width:400px;">
                <img src="@/assets/logos/Leader.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo3">
                <img src="@/assets/logos/gob.pe.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;">
                <img src="@/assets/logos/Gowi.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:200px">
                <img src="@/assets/logos/HIlaturas_Ferre.jpg" style="width: 100%;" alt="">
            </div>


            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:300px">
                <img src="@/assets/logos/Maquinarias.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:300px">
                <img src="@/assets/logos/Maxi_Force.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:200px">
                <img src="@/assets/logos/Medik8.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:200px">
                <img src="@/assets/logos/HaseebTextile.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:200px">
                <img src="@/assets/logos/LavaTextiles.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:130px">
                <img src="@/assets/logos/JNE.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:210px">
                <img src="@/assets/logos/Miyasato.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:250px">
                <img src="@/assets/logos/MunicipalidadSanIsidro.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:250px">
                <img src="@/assets/logos/Patrocinio.jpg" style="width: 100%;" alt="">
            </div>
            
            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:250px">
                <img src="@/assets/logos/BancoDeLaNacion.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:190px">
                <img src="@/assets/logos/Gravetal.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:140px">
                <img src="@/assets/logos/Real_Plaza.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:150px">
                <img src="@/assets/logos/SanGabriel.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:250px">
                <img src="@/assets/logos/Scotiabank.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:220px">
                <img src="@/assets/logos/Sodimac.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:230px">
                <img src="@/assets/logos/Horizon.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:250px">
                <img src="@/assets/logos/Solehre.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:230px">
                <img src="@/assets/logos/Sunat.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:130px">
                <img src="@/assets/logos/TC2.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:150px">
                <img src="@/assets/logos/TheGreenery.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:80px">
                <img src="@/assets/logos/Topfrut.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:80px">
                <img src="@/assets/logos/Grup_Pons.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:230px">
                <img src="@/assets/logos/Trend_Food.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:230px">
                <img src="@/assets/logos/Ugurteks.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:250px">
                <img src="@/assets/logos/Win_Stone.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:260px">
                <img src="@/assets/logos/Zaditivos.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:120px">
                <img src="@/assets/logos/Yanuq.png" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:120px">
                <img src="@/assets/logos/ComercialBP.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:250px">
                <img src="@/assets/logos/ConexionRitmica.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo">
                <img src="@/assets/logos/Almon.jpg" style="width: 100%;" alt="">
            </div>


            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:200px">
                <img src="@/assets/logos/Enfios.jpg" style="width: 100%;" alt="">
            </div>

            <div class="logo" style="display: flex;justify-content:center;align-items:center;width:75px">
                <img src="@/assets/logos/f2m.jpg" style="width: 100%;" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Clientes",
    data() {
        return {
            
        }
    },  
    methods: {
        
    },
}
</script>

<style>
    .cliente_titulo{
        font-family: 'Arvo', serif;
        font-size: 50px;
        text-align: center;
        padding-top: 15px;
    }

    .clientes_subtitulos{
        font-family: 'Arvo', serif;
        font-size: 30px;
        text-align: center;
    }

    .logos_cliente{
        width: 100%;
        height: auto;
        padding: 50px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .logo{
        width: 150px;
        height: 150px;
        margin-right: 30px;
        margin-bottom: 15px;
    }

    .logo2{
        width: 250px;
        height: auto;
        margin-right: 30px;
        margin-bottom: 15px;
        margin-top: 20px;
    }

    .logo3{
        width: 250px;
        height: auto;
        margin-right: 30px;
        margin-bottom: 15px;
        margin-top: 40px;
    }
</style>