<template>
    <div class="contenedor_ubicacion">
        <div class="seccion_ubicacion_">
            <div class="modal_nombre_lugar">
                EE.UU.
            </div>
            <div class="foto_ubicacion">
                <img src="@/assets/Miami.jpg" class="crop" alt="">
            </div>
        </div>
        <div class="seccion_ubicacion_">
            <div class="ubicacion_mapa">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d896.6766155977024!2d-80.14883037075418!3d25.96321739897159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9ac5e74403639%3A0xf2ef351462ce5505!2s20200%20W%20Dixie%20Hwy%2C%20Miami%2C%20FL%2033180%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sgt!4v1601441135861!5m2!1ses-419!2sgt" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </div>
        <div class="seccion_ubicacion_">
            <div class="modal_nombre_lugar">
                Perú
            </div>
            <div class="foto_ubicacion">
                <img src="@/assets/ofimiraflores2.png" class="crop_2" alt="">
            </div>
        </div>
        <div class="seccion_ubicacion_">
            <div class="ubicacion_mapa">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.9165814253593!2d-77.03607278255615!3d-12.117860000000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c83d5d8c749f%3A0x69e4517a2c5bc6aa!2sC.%202%20de%20Mayo%20516%2C%20Miraflores%2015074%2C%20Per%C3%BA!5e0!3m2!1ses!2sgt!4v1659626873247!5m2!1ses!2sgt" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
       
    </div>
</template>

<script>
export default {
    name: "Ubicacion",
    data() {
        return {
            
        }
    },
    methods: {
        
    },
}
</script>

<style>
    .contenedor_ubicacion{
        width: 100%;
        height: 100%;
        display: flex;
    }
        .seccion_ubicacion_{
            width:100%;
            height: 400px;
            position: relative;
            
        }

            .modal_nombre_lugar{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                
                z-index: 1500;
                display: flex;
                justify-content: center;
                align-items: center;
                color: transparent;
                font-size: 60px;
                transition: .4s ease;
            }

            .modal_nombre_lugar:hover{
                background-color: rgba(0, 0, 0, 0.5);
                color: white;
            }

    .foto_ubicacion{
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

    }

    .foto_ubicacion > .crop {
        position:absolute;
        left: -100%;
        right: -100%;
        top: -140%;
        bottom: -100%;
        margin: auto;
        min-height: 100%;
        min-width: 100%;
    }

        .crop_2 {
            position:absolute;
            /* left: 1%; */
            /* right: -192%;
            top: -221%;
            bottom: -100%; */
            margin: auto;
            width: 100%;
            height: 100%;
            /* min-height: 100%;
            min-width: 100%; */
            /* border: 1px solid black; */
        }

    .ubicacion_mapa{
        width: 100%;
        height: 100%;
    }


    @media only screen and (min-width : 320px) and (max-width: 768px){
        .contenedor_ubicacion{
            display: block;
        }

        .seccion_ubicacion_{
            width: 100%;
        }

        .foto_ubicacion{
            width: 100%;
        }

        .ubicacion_mapa{
            width: 100%;
        }

        .linea_divisorioa_direcciones{
            display: none;
        }

        .serparador__{
            display: none;
        }
    }
</style>
