<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12" class="mt-5">
                <div class="video_en">
                    <iframe width="100%" height="650" src="https://www.youtube.com/embed/SuNsE4Zp0Kw?start=9" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <a href="https://www.planningmkt.com/PlanningMKT.mp4.zip"><button type="button" class="download_btn">DOWNLOAD VIDEO</button></a>
                
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "Video",
    data() {
        return {
            
        }
    },
    methods: {
        
    },
}
</script>

<style>
      .video_en{
        width: 100%;
        height: auto;
    }

    .download_btn{
        margin-left: 80px;
        margin-top: 20px;
        border: none;
        width: 250px;
        height: 35px;
        background-color: #ed3338;
        border-radius: 4px;
        color: white;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 3px;
    }

    @media only screen and (min-width : 320px) and (max-width: 768px){
        .download_btn{
            margin-left: unset;
            width: 100%;
        }
    }
</style>