<template>
    <div class="contenedor_contact">
        <b-container>
            <b-row>
                <b-col sm="12" class="mt-3">
                    <div class="contact_tiulo">
                        Contacts
                    </div>
                </b-col>
                 <!-- <b-col sm="12" class="mt-3">
                    <div class="contact_description">
                       Please do not hesitate to contact us.
                    </div>
                    <div class="contact_description">
                        <div class="contenedor_palabras_de_colores">
                            <div class="color4">Planning MKT</div> <div class="color3">Connecting People!</div>
                        </div>
                    </div>
                </b-col> -->
                <b-col sm="12" md="6" class="mt-3">
                    <div class="seccion_ubicacion_">
                        <div class="modal_nombre_lugar">
                            EE.UU.
                        </div>
                        <div class="foto_ubicacion">
                            <img src="@/assets/Miami.jpg" class="crop" alt="">
                        </div>
                    </div>
                </b-col>

                <b-col sm="12" md="6" class="mt-3">
                    <div class="seccion_ubicacion_">
                        <div class="modal_nombre_lugar">
                            PERÚ
                        </div>
                        <div class="foto_ubicacion">
                            <img src="@/assets/ofimiraflores2.png" class="crop_2" alt="">
                        </div>
                    </div>
                </b-col>
                
                <b-col sm="12" md="6" class="mt-3">
                    
                    <div class="divisor_direcciones">
                        <!-- <div class="addr_titulo">
                            ADDRESS EE.UU.
                        </div> -->

                            <div class="linea_divisorioa_direcciones">
                                
                            </div>

                        <div class="addr2">
                            20200 West Dixie Highway, Suite #902, Aventura, <br> Florida 33180. EE.UU.
                        </div>

                        <div class="addr">
                            PHONE:
                        </div>

                        <div class="addr2">
                            1 305 400 4827
                            <!-- 51 1 739 2488 -->
                        </div>
                    </div>
                    

                </b-col>
                <b-col sm="12" md="6" class="mt-3">

                    <!-- <div class="addr_titulo">
                        DIRECCIÓN LIMA, PERÚ
                    </div> -->

                    <div class="addr2">
                        Calle 2 de Mayo 516, Oficina 201, Miraflores, Lima - Perú 
                    </div>

                    <div class="serparador__">

                    </div>

                    <div class="addr">
                        TELÉFONO:
                    </div>


                    <div class="addr2">                    
                        51 1 739 2488
                    </div>
                   
                        <!-- <div class="datos_contacto" >
                            <div class="nombre">
                                <div class="ico_input_cover">
                                    <div class="ico_input">
                                        <img src="@/assets/user.png" style="width: 100%;">
                                    </div>
                                </div>
                                <input type="text" class="input_" name="usuario" v-model="nombre" placeholder="Name">
                            </div>
                            <div class="correo">
                                <div class="ico_input_cover">
                                    <div class="ico_input">
                                        <img src="@/assets/envelope.png" style="width: 100%;">
                                    </div>
                                </div>
                                <input type="text" class="input_" name="usuario" v-model="correo" placeholder="Email">
                            </div>
                        </div>
                        <textarea cols="30" class="input_2" rows="10" v-model="mensaje" placeholder="Your Message"></textarea>
                        <button type="button" class="btn_send mt-3" @click="enviarmensaje">SEND</button> -->
                </b-col>

                <b-col sm="12" class="mt-3">
                     <div class="addr_titulo">
                        If you would like to contact us, please write to: <br>
                       <div class="color_correo">team@planningmkt.com</div>
                    </div>
                </b-col>

            </b-row>
        </b-container>
         <div class="foot">
                         2007 | 2023 Planning MKT. All Rights Reserved
                    </div>
    </div>
</template>

<script>

//import nodemailer from 'nodemailer'
import axios from 'axios'


export default {
    name: "Contact",
    data() {
        return {
            nombre: '',
            correo: '',
            mensaje: ''
        }
    },
    methods: {
        async enviarmensaje(){

            if (this.nombre == '' || this.correo == '' || this.mensaje == '') {

                alert("All fields must be filled in")
                
            }else{
    
                await axios.get(`https://www.planningmkt.com/x.php?nombre=${this.nombre}&correo=${this.correo}&mensaje=${this.mensaje}`)
                
    
                alert('Thank you!! we will contact with you soon as possible')
    
                this.nombre = ''
                this.correo = ''
                this.mensaje = ''

            }

        }
    },
}
</script>

<style>

    .contenedor_contact{
        padding-bottom: 30px;
        background-color: #e6f9f9;
    }

    .contact_tiulo{
        font-family: 'Arvo', serif;
        font-size: 45px;
        color: #383f4c;
        margin-bottom: 30px;
        text-align: center;
        
        
    }
        .contact_description{
            font-size: 23px;
            color: #383f4c;
            
            text-align: center;
        }
            .contenedor_palabras_de_colores{
                display: flex;
                justify-content: center;
            }

        .addr_titulo{
            margin-top: 50px;
            color: #9fa3a7;
            font-weight: bold;
            font-size: 25px;
            text-align: center;
        }

        .addr{
            margin-top: 50px;
            color: #9fa3a7;
            font-weight: bold;
        }

        .addr2{
            margin-top: 10px;
            color: #383f4c;
            font-weight: bold;
            font-size: 17px;
        }

    .datos_contacto{
        display: flex;
    }
        .nombre{
            width: 50%;
            height: auto;
            margin-right: 10px;
            position: relative;

        }

        .correo{
            width: 50%;
            height: auto;
             position: relative;
        }

        .ico_input_cover{
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ico_input{
            width: 25px;
            height: 25px;
            
        }

        .input_{
            padding-left: 50px;
            width: 100%;
            height: 50px;
            background-color: #f6f6f6;
            border: 1px solid #9fa3a7;
            border-radius: 5px;
        }

        .input_2{
            margin-top: 20px;
            width: 100%;
            background-color: #f6f6f6;
            border: 1px solid #9fa3a7;
            border-radius: 5px;
            padding: 15px;
            box-sizing: border-box;
        }

        .btn_send{
            width: 150px;
            height: 40px;
            border: none;
            background-color: #ed3338;
            color: white;
            font-size: 15px;
            font-weight: bold;
            border-radius: 25px;
            outline: none !important;
            transition: .4s ease;
        }

            .btn_send:hover{
                background-color: #ec565b;
            }

            .color3{
                
                color: #1781d2;
                margin-left: 5px;
                font-weight: bold;
                
            }
                .color_correo{
                    color: #1781d2;
                }

            .color4{
                
                color: #ed3338;
                font-weight: bold;
                
            }

            .foot{
                
                margin-top: 50px;
                font-size: 14px;
                text-align: center;
            }


        .serparador__{
            height: 28px;
        }

    .divisor_direcciones{
        /* border-right: 1px solid rgb(187, 175, 175); */
        position: relative;
    }
        .linea_divisorioa_direcciones{
            border-right: 1px solid rgb(187, 175, 175);
            position: absolute;
            right: -15px;
            top: 0;
            height: 100%;
        }
</style>