<template>
  <div id="app">
    <div class="seccion_header">
        <Cabecera />
    </div>
    <div id="who" class="seccion_whoweare">
        <Who />
    </div>
    <!-- <div id="solutions" class="seccion_solutions">
        <Solutions />
    </div> -->
    <!-- <div  class="seccion_video">
        <Video />
    </div> -->
    <div id="methodology" class="seccion_metodologia">
        <Metodology />
    </div>
    <div id="clients" class="seccion_clientes">
        <Clientes />
    </div>
    <!-- <div id="contactus" class="seccion_ubicacion">
        <Ubicacion />
    </div> -->
    <div id="contactus" class="seccion_contacto">
        <Contact />
    </div>
  </div>
</template>

<script>

const $ = require('jquery')
window.$ = $

import Cabecera from '@/components/Header/header.vue'
import Who from '@/components/Who/Who.vue'
import Solutions from '@/components/Solutions/Solutions.vue'
import Video from '@/components/Video/Video.vue'
import Metodology from '@/components/Metodology/Metodology.vue'
import Clientes from '@/components/Clients/Clientes.vue'
import Ubicacion from '@/components/Ubicacion/Ubicacion.vue'
import Contact from '@/components/Contact/Contact.vue'




export default {
  name: 'App',
  components:{
      Cabecera,
      Who,
      Solutions,
      Video,
      Metodology,
      Clientes,
      Ubicacion,
      Contact
  },
  data() {
      return {
          
      }
  },
  methods: {
      
      anclas(){

        
      }
  },
  mounted() {
    $(function() {
        $(".ancla").click(function(evento){
        evento.preventDefault();
        var codigo = "#" + $(this).data("ancla");
        $("html,body").animate({scrollTop: $(codigo).offset().top}, 1000);
        });
    });

  },
  
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

.seccion_header{
    width: 100%;
    height: 700px;
    background-color: #ed3338;
    position: relative;
    z-index: 10;
}

.seccion_whoweare{
    width: 100%;
    height: auto;
}

.seccion_solutions{
    width: 100%;
    height: auto;
    background-color: #d7f6f6;
    padding-bottom: 30px; /* tentativo */
}

.seccion_video{
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    background-color: #d7f6f6;
}

.seccion_metodologia{
    width: 100%;
    height: 1150px;
    background-color: #fbeae9;
    position: relative;
}

.seccion_clientes{
    width: 100%;
    height: auto;
}
.seccion_ubicacion{
    width: 100%;
    height: auto;
}

.seccion_contacto{
    width: 100%;
    height: auto;
}

a{
    text-decoration: none !important;
    color: unset !important;
}

@media only screen and (min-width : 320px) and (max-width: 768px){
    .seccion_metodologia{
        height: auto;
        padding-bottom: 15px;
    }
}
</style>
