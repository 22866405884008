<template>
    <div class="contenedor_who">
        <div class="texto_who">
            Who We Are
        </div>

        <button type="button" class="btn_celeste">
            <!-- <div class="xv">Planning MKT </div>
            <div style="color: #baff00 !important;margin-left:10px;">Connecting People</div> -->
            Planning MKT &nbsp; <span style="margin-left: 5px;">Connecting People</span>
        </button>

        <div class="explicacion">
            <div class="t_exp">
                <!-- As a reliable and multidisciplinary team of innovators, we pride ourselves on our ability to solve complex problems in Marketing and International Trade. Planning Mkt is a global network comprised of reputable specialists from over 37 countries worldwide. -->
                
                We are a regional multidisciplinary team focused on gathering and interpreting fresh data to create connection channels with your potential partners, your customers. 

            </div>
        
            <div class="t_exp" style="margin-top: 40px;">
               <!-- We have a talented team of professionals who are passionate about helping you reach your goals and breach boundaries. Working with local experts gives us a considerable advantage that guarantees the tangible outcomes you need to bolster the future of your project. -->

               We know that differentiation is the most valuable asset in your business model. We will be your idea factory to set you above the rest in your segment.

            </div>


            <div class="t_exp" style="margin-top: 40px;">
                <!-- From Market Segmentation to Differentiation Strategy, we are tactful in our decision-making process and can guarantee creative, original, and substantial results! -->

                Our Methodology is centered around Community, Authenticity, and Real Engagement between Passionate Individuals and Brands. <br><br>
                We incorporate the latest marketing technology tools to build long-term relationships between our clients and their potential partners/clients in Latin America.

            </div>

            <!-- <div class="t_exp2" style="margin-top: 40px;">
                Our global alliance allow us to understand each market from the hand of local experts.
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "Who",
    data() {
        return {
            
        }
    },
    methods: {
        
    },
}
</script>

<style>
    .contenedor_who{
        width: 100%;
        height: 100%;
        background-image: url("../../assets/12232.jpg");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding-top: 130px;
        padding-left: 150px;
        padding-bottom: 75px;
        box-sizing: border-box;
    }
        .texto_who{
            font-family: 'Arvo', serif;
            color: white;
            font-size: 75px;
        }

        .btn_celeste{
            font-family: 'Open Sans', sans-serif;
            border: none;
            outline: none !important;
            background-color: #1781d2;
            color: white;
            width: 315px;
            height: 40px;
            font-weight: bold;
            font-size: 17px;
            border-radius: 25px;
            transition: .3s ease;
            display: flex;
            align-items: center;
            padding-left: 25px;
        }

            .btn_celeste:hover{
                background-color: #295a80;
            }

    .explicacion{
        font-family: 'Arvo', serif;
        width: 85%;
        height: auto;
        margin-top: 70px;
        border-radius: 4px;
        background-color: rgba(234, 54, 62, 0.8);
        padding-left: 50px;
        padding-right: 45px;
        padding-top: 15px;
        padding-bottom: 25px;
    }
        .t_exp{
            font-size: 30px;
            color: #ffffff;
            line-height: 40px;
            font-weight: 300;
        }

        .t_exp2{
            font-size: 40px;
            font-weight: 700;
            color: #ffffff;
            line-height: 40px;
        }

        span{
            color: #baff00;
            font-weight: bold;
        }

    @media only screen and (min-width : 320px) and (max-width: 768px){
        .contenedor_who{
            padding: 10px;
        }

        .explicacion{
            margin-top: 15px;
            width: 100%;
            padding: 15px;
        }

        .t_exp{
            font-size: 30px;
            line-height: 35px;
            text-align: center;
        }

        .t_exp2{
            text-align: center;
            font-size: 30px;
        }
        
        .texto_who{
            font-size: 55px;
            margin-bottom: 20px;
            text-align: center;
        }


        .xv{
            margin-right: 10px;
        }
    }
</style>